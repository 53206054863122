import "./styles.scss";

interface IRequiredFieldProps {
  text: string;
}

export const RequiredField = ({ text }: IRequiredFieldProps) => (
  <>
    {text}
    <span className="required-field">*</span>
  </>
);
