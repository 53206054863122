import Axios, { AxiosRequestConfig } from "axios";
import axios from "axios";
import { downloadFile, getPdf } from "../utils";

export const postRequest = (url: string, payload?: any, params?: any) => Axios.post(url, payload, { params });
export const putRequest = (url: string, payload?: any) => Axios.put(url, payload);
export const getRequest = (url: string, payload?: any) => Axios.get(url, { params: payload });
export const deleteRequest = (url: string, payload?: any) => Axios.delete(url, { data: payload });
export const downloadFileRequest = (url: string, payload?: any) =>
  axios({ url, method: "POST", responseType: "blob", data: payload });

export const downloadRequest = async (url: string, config: Partial<AxiosRequestConfig> = {}) => {
  const resp = await axios({
    url: url,
    method: "POST",
    responseType: "blob",
    ...config,
  });

  downloadFile(resp, "");
};

export const printPdfRequest = async (url: string, config: Partial<AxiosRequestConfig> = {}) => {
  const resp = await axios({
    url: url,
    method: "POST",
    responseType: "blob",
    ...config,
  });

  getPdf(resp);
};

export interface BadOperationError extends Error {
  badOperation?: {
    errorCode: string;
    message: string;
  };
}

export const isBadOperationError = async (error: any, errorCode: string = "") => {
  if (error.request && error.response && error.response.status === 400) {
    if (
      error.badOperation === undefined &&
      error.request.responseType === "blob" &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf("json") !== -1
    ) {
      error.badOperation = JSON.parse(await error.response.data.text());
    }

    if (!!error.badOperation && error.badOperation.errorCode && error.badOperation.errorCode === "BadOperation") {
      return errorCode === null || errorCode === "" || errorCode === error.badOperation.message;
    }
  }
  return false;
};
