import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { MessageBar, MessageBarType, PrimaryButton, DefaultButton } from "@fluentui/react";
import { CheckUpdatesContext } from "../../../context/checkUpdatesContext";
import { AuthContext } from "../../../context/authContext";
import { AppInsightsContext } from "../../../context/appInsightsContext";
import { Navbar } from "../../common/Navbar";
import { LeftMenu } from "../../common/LeftMenu";
import { ConfirmModal } from "../../common/ConfirmModal";
import loc from "../../../localization";
import { ErrorBoundary } from "../ErrorBoundary";
import { IPageWrapperProps } from "./types";
import "./styles.scss";

export const PageWrapper = (props: IPageWrapperProps) => {
  let location = useLocation();
  const { haveUpdates } = useContext(CheckUpdatesContext);
  const {
    user,
    tokenExpiredModal,
    networkErrorModal,
    setTokenExpiredModal,
    setNetworkErrorModal,
    onRelogin,
    handleLogout,
  } = useContext(AuthContext);
  const { trackView } = useContext(AppInsightsContext);
  const [connectionIsChecking, setConnectionIsChecking] = useState<boolean>(false);
  const reloadPage = () => {
    trackView(user, "The app has been updated to the latest version");
    window.location.reload();
  };
  const retryConnection = () => {
    setConnectionIsChecking(true);
    if (navigator.onLine) {
      setNetworkErrorModal(false);
    } else {
      setTimeout(() => setConnectionIsChecking(false), 1000);
    }
  };
  useEffect(() => {
    if (haveUpdates) reloadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div className="page-wrapper">
      <Navbar />
      <div className="page-wrapper-content">
        <LeftMenu />
        <div className="page-wrapper__have-updates-wrapper">
          <ErrorBoundary>{props.children}</ErrorBoundary>
          {haveUpdates && (
            <MessageBar className="have-updates-message" messageBarType={MessageBarType.warning}>
              {loc.warnings.appHaveUpdates}&nbsp;
              <u onClick={reloadPage}>{loc.buttons.update}</u>
            </MessageBar>
          )}
        </div>
      </div>
      {tokenExpiredModal && (
        <ConfirmModal
          text={"Token expired. Get a new token?"}
          leftButton={<PrimaryButton text="Login" onClick={onRelogin} />}
          rightButton={<DefaultButton text="Logout" onClick={handleLogout} />}
          onDismiss={() => setTokenExpiredModal(false)}
        />
      )}
      {networkErrorModal && (
        <ConfirmModal
          isLoading={connectionIsChecking}
          text={"Network connection failed. Please check your internet connection."}
          leftButton={<PrimaryButton text="Retry" onClick={retryConnection} />}
          rightButton={<div />}
          onDismiss={() => setNetworkErrorModal(false)}
        />
      )}
    </div>
  );
};
