import { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { format } from "date-fns";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { Loading } from "../components/wrappers/LoadingWrapper";
import { Navbar } from "../components/common/Navbar";
import { ConfirmModal } from "../components/common/ConfirmModal";
import loc from "../localization";
import { loginRequest } from "../authConfig";
import { AppInsightsContext } from "./appInsightsContext";
import { AppLanguage, BadOperations, Notification } from "../constants/enums";
import { IAlert } from "../typings";
import { USER_ACCESS_ENDPOINT } from "../constants/endpoints";
import { initPermissions } from "../constants/objects";
import { getNotification } from "../utils";
import { getPermissionScopes, IPermissions } from "../utils/getPermissionScopes";
import { IUser } from "./types";

export interface IAuthContext {
  name?: string;
  isAuthenticated: boolean;
  user: IUser | null;
  tokenExpiredModal: boolean;
  setTokenExpiredModal: (val: boolean) => any;
  networkErrorModal: boolean;
  setNetworkErrorModal: (val: boolean) => any;
  permissions: IPermissions;
  requestAccessToken: () => any;
  onRelogin: () => void;
  handleLogout: () => void;
}

export const AuthContext = createContext<IAuthContext>({
  name: "",
  isAuthenticated: false,
  user: null,
  tokenExpiredModal: false,
  networkErrorModal: false,
  permissions: initPermissions,
  setTokenExpiredModal: () => {},
  setNetworkErrorModal: () => {},
  requestAccessToken: () => {},
  onRelogin: () => {},
  handleLogout: () => {},
});

export const AuthContextProvider = ({ children }: any) => {
  // const controller = new AbortController();
  const isAuthenticated = useIsAuthenticated();
  const { trackTrace } = useContext(AppInsightsContext);
  const { instance, accounts } = useMsal();
  const [accessDenied, setAccessDenied] = useState<boolean>(true);
  const [user, setUser] = useState<IUser | null>(null);
  const [error, setError] = useState<string>("");
  const [tokenExpiredModal, setTokenExpiredModal] = useState<boolean>(false);
  const [networkErrorModal, setNetworkErrorModal] = useState<boolean>(false);
  const permissions = getPermissionScopes(user);
  const name = accounts[0] && accounts[0].name;

  const handleLogout = () => {
    setAccessDenied(true);
    instance
      .handleRedirectPromise()
      .then(() => {
        const account = instance.getActiveAccount();
        if (!account) {
          instance.logoutRedirect();
        }
      })
      .catch((e: Error) => getNotification("Error", e.message, Notification.ERROR));
  };

  const getUserAccessRequest = async () => {
    const token = await requestAccessToken();
    axios
      .get(`${USER_ACCESS_ENDPOINT}/me`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        if (res.data.isAuthenticated) {
          setUser(res.data.user);
          setAccessDenied(false);
        }
      })
      .catch((err) => {
        const errorMessage = err.response?.status === 403 ? loc.warnings.forbidden : loc.warnings.somethingGoesWrong;
        setError(errorMessage);
      });
  };

  const requestAccessToken = async () => {
    const silentRequest = { ...loginRequest, account: accounts[0], forceRefresh: false };
    // const request = { ...loginRequest, loginHint: accounts[0]?.username };
    let showNetworkError: boolean = false;
    let showTokenExpiredError: boolean = false;
    try {
      const tokenResponse = await instance.acquireTokenSilent(silentRequest);
      return tokenResponse?.accessToken;
    } catch (error: any) {
      console.log(12345, error, Object.entries(error));
      if (error?.message === "Network Error" && !networkErrorModal) {
        console.log("Network Error");
        // setNetworkErrorModal(true);
        showNetworkError = true;
      } else if (
        error?.errorCode === "login_required" ||
        error?.errorCode === "multiple_matching_tokens" ||
        error?.errorCode === "interaction_required" ||
        error?.error === "invalid_grant"
      ) {
        console.log(456, error?.errorCode, error?.error);
        // setTokenExpiredModal(true);
        if (error?.errorCode === "multiple_matching_tokens") {
          console.log("Clear local storage");
          localStorage.clear();
        }
        showTokenExpiredError = true;
      } else {
        // getNotification('Error', error.toString(), Notification.ERROR);
        console.log(999, error.toString());
      }
      if (showNetworkError) {
        console.log("showNetworkError");
        setNetworkErrorModal(true);
      }
      if (showTokenExpiredError) {
        console.log("showTokenExpiredError");
        setTokenExpiredModal(true);
      }
      return null;
    }
    // const tokenResponse = await instance.acquireTokenSilent(silentRequest)
    // 	.catch(error => {
    // 		if (error) {
    // 			console.log(12345, error, Object.entries(error));
    // 			if (error?.message === "Network Error" && !networkErrorModal) {
    // 				console.log('Network Error');
    // 				setNetworkErrorModal(true);
    // 			} else if (
    // 				error?.errorCode === "login_required"
    // 				|| error?.errorCode === "multiple_matching_tokens"
    // 				|| error?.error === "invalid_grant"
    // 			) {
    // 				console.log(456, error?.errorCode, error?.error);
    // 				if (error?.errorCode === "multiple_matching_tokens") {
    // 					console.log('Clear local storage');
    // 					localStorage.clear();
    // 				};
    // 				// setTokenExpiredModal(true);
    // 			} else {
    // 				getNotification('Error', error.toString(), Notification.ERROR)
    // 			};
    // 		};
    // 	});
    // return tokenResponse?.accessToken;
  };

  const onRelogin = () => {
    instance.loginRedirect(loginRequest).catch((e: Error) => console.error(e));
  };

  const defineInterceptors = () => {
    axios.interceptors.request.use(async (config) => {
      const token = await requestAccessToken();
      if (config.headers) config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const { config, status, headers, data } = error.response;
        const dataMessage = data && data?.message;
        const isNetworkError: boolean = error?.message === "Network Error";
        const resourseNotFound = data?.errorCode === "ResourceNotFound";
        if (isNetworkError) setNetworkErrorModal(true);
        if (!isNetworkError && status !== 400 && status !== 429 && !resourseNotFound) {
          const correlationId = headers["x-correlation-id"];
          const jsonAlerts = sessionStorage.getItem("alerts");
          const alerts: IAlert[] = jsonAlerts && (JSON.parse(jsonAlerts) || []);
          const alert = {
            errorCode: `${status}`,
            message: error.message,
            correlationId,
            time: format(new Date(), "HH:mm"),
            date: format(new Date(), "dd/MM/yyyy"),
          };
          sessionStorage.setItem("alerts", JSON.stringify([alert, ...alerts]));
        }
        if (!isNetworkError && status === 400) {
          const unhandledError = !Object.keys(BadOperations).includes(dataMessage);
          if (unhandledError) {
            trackTrace(user, `Unhadled error 400: ${dataMessage}`, {
              page: window.location.pathname,
              request: config.url,
            });
          } else {
            const resultMessage = data?.result?.message;
            const unhandledResultError = !Object.keys(BadOperations).includes(resultMessage);
            if (unhandledResultError) {
              trackTrace(user, `Unhadled error 400: ${resultMessage}`, {
                page: window.location.pathname,
                request: config.url,
              });
            }
          }
        }
        if (!isNetworkError && status === 500) {
          trackTrace(user, dataMessage, { page: window.location.pathname, request: config.url });
        }
        throw error;
      },
    );
  };

  useEffect(() => {
    defineInterceptors();
    getUserAccessRequest();
    loc.setLanguage(localStorage.getItem("appLanguage") || AppLanguage.Russian);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider
      value={{
        name,
        user,
        tokenExpiredModal,
        setTokenExpiredModal,
        networkErrorModal,
        setNetworkErrorModal,
        permissions,
        isAuthenticated,
        requestAccessToken,
        onRelogin,
        handleLogout,
      }}
    >
      {isAuthenticated && !accessDenied ? (
        children
      ) : (
        <div className="error-page">
          <Navbar />
          {error.length ? (
            <div className="error-page-content">{error}</div>
          ) : (
            <Loading isLoading>
              <div className="error-page-content"></div>
            </Loading>
          )}
          {tokenExpiredModal && (
            <ConfirmModal
              text={"Token expired. Get a new token?"}
              leftButton={<PrimaryButton text="Login" onClick={onRelogin} />}
              rightButton={<DefaultButton text="Logout" onClick={handleLogout} />}
              onDismiss={() => setTokenExpiredModal(false)}
            />
          )}
        </div>
      )}
    </AuthContext.Provider>
  );
};
