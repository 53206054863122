import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { map, last, isNull, filter, find } from "lodash-es";
import { CommandButton, DirectionalHint, TooltipHost, FontIcon } from "@fluentui/react";
import { AuthContext } from "../../../context/authContext";
import loc from "../../../localization";
import appLogo from "../../../images/appLogo.png";
import { getMenuItems } from "./config";
import "./styles.scss";

const RenderLinkList = (props: any) => {
  const { pathname } = window.location;
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const toggleList = () => setIsExpanded(!isExpanded);
  const linkIsActive = (url: string) =>
    url.includes("/counterparty") ? pathname.includes(url.split("/").slice(0, -1).join("/")) : pathname.includes(url);
  useEffect(() => {
    find(props.item.links, (link) => {
      if (pathname.includes("forLicenses") && !link.key.toLowerCase().includes("licenses")) return false;
      return pathname.includes(link.key) || map(link?.links, (item) => item.url).includes(pathname);
    })
      ? setIsExpanded(true)
      : setIsExpanded(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className={`link-expanded-block ${isExpanded ? "is-expanded" : ""}`}>
      <div className="link-expanded-block-title flex-row" onClick={toggleList}>
        <div className={`${!!props.icon ? "icon-wrapper" : ""}`} style={props.item.style}>
          {props.icon}
          {props.item.name}
        </div>
        <FontIcon iconName={isExpanded ? "ChevronUpMed" : "ChevronDownMed"} />
      </div>
      {isExpanded ? (
        <ul>
          {map(props.item.links, (link) => (
            <li
              key={link.key}
              className={`${link.url && linkIsActive(link.url) ? "is-active" : ""} ${
                link.disabled ? "is-disabled" : ""
              }`}
              style={{ borderBottom: link.underline ? "1px solid #8c8c8c" : "" }}
            >
              {!link.links?.length ? (
                link.newTab ? (
                  <a href={link.url} style={link.style} target="_blank" rel="noreferrer">
                    {link.name}
                  </a>
                ) : (
                  <Link to={link.url} style={link.style}>
                    {link.name}
                  </Link>
                )
              ) : (
                <RenderLinkList item={link} />
              )}
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

const RenderCalloutContent = ({ item }: any) => (
  <ul>
    {map(
      item.links,
      (link) =>
        !link.disabled && (
          <li key={link.key}>
            {link.url ? (
              link.newTab ? (
                <a href={link.url} target="_blank" rel="noreferrer">
                  {link.name}
                </a>
              ) : (
                <Link to={link.url}>{link.name}</Link>
              )
            ) : !link.links?.length ? (
              <Link to={link.url}>{link.name}</Link>
            ) : (
              <>
                {filter(link.links, (link) => !link.disabled).length ? link.name : ""}
                <RenderCalloutContent item={link} />
              </>
            )}
          </li>
        ),
    )}
  </ul>
);

const RenderCalloutWrapper = ({ children, item, leftMenuIsVisible }: any) =>
  leftMenuIsVisible ? (
    children
  ) : (
    <TooltipHost
      className="tooltip-menu"
      tooltipProps={{
        onRenderContent: () =>
          item.disabled ? null : (
            <div>
              <div className="title">{item.name}</div>
              {item?.links ? <RenderCalloutContent item={item} /> : null}
            </div>
          ),
      }}
      calloutProps={{ directionalHint: DirectionalHint.rightTopEdge, isBeakVisible: false }}
    >
      {children}
    </TooltipHost>
  );

const RenderIconWrapper = ({ item, children }: any) =>
  !item.links?.length ? <Link to={item.url}>{children}</Link> : children;

export const LeftMenu = () => {
  const { permissions } = useContext(AuthContext);
  const menuIsCollapsed = localStorage.getItem("menuIsCollapsed");
  const menuMode = menuIsCollapsed === "false" || isNull(menuIsCollapsed) ? true : false;
  const [leftMenuIsVisible, setLeftMenuIsVisible] = useState<boolean>(menuMode);
  const activeKey = last(window.location.pathname.split("/"));
  const activeKeyForIcon = window.location.pathname.split("/")[1];
  const onChangeLeftMenuMode = () => {
    setLeftMenuIsVisible(!leftMenuIsVisible);
    localStorage.setItem("menuIsCollapsed", `${leftMenuIsVisible}`);
  };
  const onOpenLeftMenu = () => setLeftMenuIsVisible(true);
  useEffect(() => {
    setTimeout(() => {
      const layer = document.querySelectorAll(".is-active");
      const activeLink = layer[layer.length - 1];
      activeLink?.scrollIntoView({ behavior: "smooth", block: "center" });
    }, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey, leftMenuIsVisible]);
  return (
    <div className="left-menu" style={{ flex: leftMenuIsVisible ? "1" : "0.1" }}>
      <ul className="left-menu-list" style={{ paddingTop: leftMenuIsVisible ? 2 : 0 }}>
        {map(getMenuItems(permissions), (item) => (
          <RenderCalloutWrapper key={item.key} item={item} leftMenuIsVisible={leftMenuIsVisible}>
            <li
              className={`
                ${activeKey === item.key && item.key !== "faq" ? "is-active" : ""}
                ${item.key === "faq" && activeKey === item.key ? "faq-page" : ""}
                ${item.disabled ? "is-disabled" : ""}
                ${!leftMenuIsVisible && activeKeyForIcon === item.key ? "active-icon" : ""}
              `}
              style={{ paddingLeft: leftMenuIsVisible ? 0 : 17, minHeight: leftMenuIsVisible ? "auto" : 24 }}
            >
              {!leftMenuIsVisible && (
                <RenderIconWrapper item={item}>
                  <FontIcon
                    iconName={item.iconName}
                    className={`menu-point-icon ${!leftMenuIsVisible ? "mobile-icon" : ""}`}
                  />
                </RenderIconWrapper>
              )}
              <div
                className={`left-menu-list-point ${item.links?.length ? "current" : ""}`}
                style={{ display: leftMenuIsVisible ? "block" : "none", paddingLeft: !item.links?.length ? 10 : 0 }}
              >
                {!item.links?.length ? (
                  <Link to={item.url}>
                    <FontIcon iconName={item.iconName} onClick={onOpenLeftMenu} />
                    {item.name}
                  </Link>
                ) : (
                  <RenderLinkList item={item} icon={<FontIcon iconName={item.iconName} onClick={onOpenLeftMenu} />} />
                )}
              </div>
            </li>
          </RenderCalloutWrapper>
        ))}
      </ul>
      <div className="left-menu-settings" style={{ flexDirection: leftMenuIsVisible ? "row" : "column" }}>
        <CommandButton
          text={leftMenuIsVisible ? loc.menu.startPage : ""}
          href="/dashboard"
          className="home-icon"
          iconProps={{ imageProps: { src: `${appLogo}`, maximizeFrame: true } }}
        />
        <span
          className="left-menu-mode-btn"
          onClick={onChangeLeftMenuMode}
          style={{ marginTop: leftMenuIsVisible ? 0 : 10 }}
        >
          <FontIcon
            iconName={leftMenuIsVisible ? "DoubleChevronLeft12" : "DoubleChevronRight12"}
            style={{ marginRight: leftMenuIsVisible ? 16 : 7, marginBottom: leftMenuIsVisible ? 0 : 5 }}
          />
        </span>
      </div>
    </div>
  );
};
