import React from "react";
import { useMsal } from "@azure/msal-react";
import { PrimaryButton } from "@fluentui/react";
import { IPublicClientApplication } from "@azure/msal-browser";
import { loginRequest } from "../../../authConfig";
import { Navbar } from "../../common/Navbar";
import "./styles.scss";

const handleLogin = (instance: IPublicClientApplication) => {
  instance.loginRedirect(loginRequest).catch((e: Error) => console.error(e));
};

export const SignInPage = () => {
  const { instance } = useMsal();
  return (
    <div className="unauthenticated-page">
      <Navbar />
      <p>You are not signed in! Please sign in.</p>
      <PrimaryButton text="Login" onClick={() => handleLogin(instance)} />
    </div>
  );
};
