import { useEffect, useContext } from "react";
import { AppInsightsContext } from "../../../context/appInsightsContext";
import { AuthContext } from "../../../context/authContext";
import { LanguageContext } from "../../../context/languageContext";
import { PageWrapper } from "../../wrappers/PageWrapper";

export const AccessDeniedPage = (props: any) => {
  const { user } = useContext(AuthContext);
  const { trackView } = useContext(AppInsightsContext);
  const { loc } = useContext(LanguageContext);
  useEffect(() => {
    trackView(user, `Attempt to get the page using the ${props.location.state.from}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PageWrapper>
      <div className="container">
        <h5 className="error-message">{loc.warnings.accessDenied}</h5>
      </div>
    </PageWrapper>
  );
};
