import { useEffect, useRef } from "react";
import { getLayer } from "./detailsListKeydown";

export function useEventListener(eventName: string, handler: (e: Event) => void, element?: any) {
  if (!element) element = window;
  const savedHandler: any = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);
  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;
    const eventListener = (event: Event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element]);
}
export function useEventListenerBySelector(eventName: string, handler: (e: Event) => void, selector?: any) {
  const savedHandler: any = useRef();
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    let element: any = document.querySelector(".ms-ScrollablePane--contentContainer");
    if (!element) element = window;

    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: Event) => savedHandler.current(event);
    element.addEventListener(eventName, eventListener);
    return () => {
      element.removeEventListener(eventName, eventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handler]);
}

export function useLayerEventListener(eventName: string, handler: (e: Event) => void) {
  useEffect(() => {
    const element = getLayer() || document;
    element.addEventListener(eventName, handler);
    return () => element.removeEventListener(eventName, handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
