import { useContext } from "react";
import { LanguageContext } from "../../../context/languageContext";
import { PageWrapper } from "../../wrappers/PageWrapper";

export const NotFoundPage = () => {
  const { loc } = useContext(LanguageContext);
  return (
    <PageWrapper>
      <div className="container">
        <h5>{loc.warnings.pageNotFound}</h5>
      </div>
    </PageWrapper>
  );
};
