import { Spinner, SpinnerSize } from "@fluentui/react";
import { useState, useEffect } from "react";
import { getRequest } from "../../../actions";
import { Notification } from "../../../constants/enums";
import { getNotification } from "../../../utils";
import "./styles.scss";

interface IWidgetProps {
  title: string;
  type: string;
  link: string;
  backgroundColor: string;
  endpoint: string;
}

export const Widget = (props: IWidgetProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [count, setCount] = useState<number>();
  const getCount = () => {
    setIsLoading(true);
    getRequest(props.endpoint)
      .then((res) => setCount(res.data))
      .catch((err) => getNotification("Error", err.message, Notification.ERROR))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    getCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <a key={props.title} href={props.link}>
      <div className="widget" style={{ backgroundColor: props.backgroundColor }}>
        <div className="widget-title">{props.type}</div>
        <div className="widget-count">{count}</div>
        <div className="widget-info">{props.title}</div>
        {isLoading && <Spinner className="full-loader" size={SpinnerSize.large} />}
      </div>
    </a>
  );
};
