import { AUDIENCE, CLIENT_ID, TENANT_ID } from "./constants/appConstants";

export const msalConfig = {
  auth: {
    clientId: CLIENT_ID,
    authority: `https://login.microsoftonline.com/${TENANT_ID}`,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: [`${AUDIENCE}/user_impersonation`],
};
