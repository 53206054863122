import React, { createContext, useEffect, useState, useContext } from "react";
import loc from "../localization";
import { AppLanguage } from "../constants/enums";
import { AuthContext } from "./authContext";

export interface Props {
  children: JSX.Element | JSX.Element[];
}

interface ILanguageContext {
  language: string;
  loc: any;
  changeAppLanguage: (val: string) => void;
}

export const LanguageContext = createContext<ILanguageContext>({
  language: "",
  loc: "",
  changeAppLanguage: () => {},
});

export const LanguageContextProvider = (props: Props) => {
  const { user } = useContext(AuthContext);
  const [language, setLanguage] = useState<string>(user?.language === "r" ? AppLanguage.Russian : AppLanguage.English);

  const initAppLanguage = () => {
    const lang = localStorage.appLanguage;
    lang !== language && changeAppLanguage(language);
  };

  const changeAppLanguage = (lang: string) => {
    setLanguage(lang);
    localStorage.setItem("appLanguage", lang);
  };

  useEffect(() => {
    initAppLanguage(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  loc.setLanguage(language);

  return (
    <LanguageContext.Provider value={{ loc, language, changeAppLanguage }}>{props.children}</LanguageContext.Provider>
  );
};
