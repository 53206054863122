export const APP_ENV = process.env.REACT_APP_ENV;
export const APP_VERSION = process.env.REACT_APP_VERSION;

export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || "";
export const TENANT_ID = process.env.REACT_APP_TENANT_ID;
export const AUDIENCE = process.env.REACT_APP_AUDIENCE;
export const INSTRUMENTATION_KEY = process.env.REACT_APP_INSTRUMENTATION_KEY;

export const SHIPMENTS_FINVALDA_DISABLED: boolean = process.env.REACT_APP_SHIPMENTS_FINVALDA === "disabled";
export const COUNTERPARTY_FINVALDA_DISABLED: boolean = process.env.REACT_APP_COUNTERPARTY_FINVALDA === "disabled";
export const DEVICES_ORDER_FORMS_DISABLED: boolean = process.env.REACT_APP_DEVICES_ORDER_FORMS === "disabled";
export const DEVICES_ORDER_FORMS_SPARE_PARTS_DISABLED: boolean = process.env.REACT_APP_DEVICES_ORDER_FORMS_SPARE_PARTS === "disabled";
export const ORDER_FORMS_PAGE_DISABLED: boolean = process.env.REACT_APP_ORDER_FORMS_PAGE === "disabled";
export const ORDER_FORMS_SPARE_PAGE_DISABLED: boolean = process.env.REACT_APP_ORDER_FORMS_SPARE_PAGE === "disabled";
export const ORDERS_SPARE_PAGE_DISABLED: boolean = process.env.REACT_APP_ORDERS_SPARE_PAGE_DISABLED === "disabled";
export const DISTRIBUTOR_ORDERS_PAGE_DISABLED: boolean = process.env.REACT_APP_DISTRIBUTOR_ORDERS_PAGE === "disabled";
export const SHIPMENT_REQUESTS_PAGE_DISABLED: boolean = process.env.REACT_APP_SHIPMENT_REQUESTS_PAGE === "disabled";
export const ORDER_FORMS_ADDITIONS_PAGE_DISABLED: boolean =
  process.env.REACT_APP_ORDER_FORMS_ADDITIONS_PAGE === "disabled";
export const REPORTS_DISABLED: boolean = process.env.REACT_APP_REPORTS === "disabled";
export const PRICE_CALCULATION_PAGE_DISABLED: boolean = process.env.REACT_APP_PRICE_CALCULATION_PAGE === "disabled";
export const UPLOAD_SHIPMENT_SCHEDULE_PAGE_DISABLED: boolean =
  process.env.REACT_APP_UPLOAD_SHIPMENT_SCHEDULE_PAGE === "disabled";
export const UPLOAD_PRICES_PAGE_DISABLED: boolean = process.env.REACT_APP_UPLOAD_PRICES_PAGE === "disabled";
export const ACCESS_SETTINGS_TO_SALES_DISABLED: boolean = process.env.REACT_APP_ACCESS_SETTINGS_TO_SALES === "disabled";
export const WERE_DEVICES_WERE_SOLD_DISABLED: boolean = process.env.REACT_APP_WERE_DEVICES_WERE_SOLD === "disabled";
export const FORECAST_REPORT_DISABLED: boolean = process.env.REACT_APP_FORECAST_REPORT === "disabled";
export const FAQ_PAGE_DISABLED: boolean = process.env.REACT_APP_FAQ_PAGE === "disabled";
export const POS_PAGE_DISABLED: boolean = process.env.REACT_APP_POS_PAGE === "disabled";
export const DISRIBUTOR_ORDERS_POS_PAGE_DISABLED: boolean = process.env.REACT_APP_DISTRIBUTOR_ORDER_POS_PAGE === "disabled";
export const SHIPMENT_REQUESTS_POS_PAGE_DISABLED: boolean = process.env.REACT_APP_SHIPMENT_REQUESTS_POS_PAGE === "disabled";
export const PROFORMAS_PAGE_DISABLED: boolean = process.env.REACT_APP_PROFORMAS_PAGE === "disabled"