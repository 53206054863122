import React, { createContext, useEffect, useState } from "react";
import { createBrowserHistory } from "history";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { APP_VERSION, INSTRUMENTATION_KEY } from "../constants/appConstants";
import { IUser } from "./types";

export interface Props {
  children: JSX.Element | JSX.Element[];
}

interface IAppInsightsContext {
  trackView: (user: IUser | null, message: string, props?: any) => void;
  trackTrace: (user: IUser | null, message: string, props?: any) => void;
}

export const AppInsightsContext = createContext<IAppInsightsContext>({
  trackView: () => {},
  trackTrace: () => {},
});

export const AppInsightsContextProvider = (props: Props) => {
  const [appInsights, setAppInsights] = useState<any>(null);

  const trackTrace = (user: IUser | null, message: string, props?: any) => {
    appInsights?.trackTrace(
      { message },
      {
        Application: "Yukon.Sales.WebSpa",
        version: APP_VERSION,
        user: `${user?.workLogin} (${user?.workNumber})`,
        ...props,
      },
    );
  };

  const trackView = (user: IUser | null, message: string, props?: any) => {
    appInsights?.trackEvent(
      { name: message },
      {
        Application: "Yukon.Sales.WebSpa",
        user: `${user?.workLogin} (${user?.workNumber})`,
        version: APP_VERSION,
        ...props,
      },
    );
  };

  // const trackView = (user: IUser | null, message: string, props?: any) => {
  // 	appInsights?.trackPageView({
  // 		name: message,
  // 		properties: {
  // 			Application: 'Yukon.Sales.WebSpa',
  // 			user: `${user?.workLogin} (${user?.workNumber})`,
  // 			...props,
  // 		},
  // 	});
  // };

  useEffect(() => {
    const browserHistory = createBrowserHistory();
    const reactPlugin = new ReactPlugin();
    const appInsightsObj = new ApplicationInsights({
      config: {
        connectionString: [
          `InstrumentationKey=${INSTRUMENTATION_KEY}`,
          "IngestionEndpoint=https://northeurope-2.in.applicationinsights.azure.com/",
          "LiveEndpoint=https://northeurope.livediagnostics.monitor.azure.com/",
        ].join(";"),
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory },
        },
      },
    });
    if (process.env.REACT_APP_ENV !== "DEV/QA") {
      setAppInsights(appInsightsObj);
    }
  }, []);

  useEffect(() => {
    if (appInsights) {
      appInsights?.loadAppInsights();
    }
    // appInsights.trackPageView();
  }, [appInsights]);

  return <AppInsightsContext.Provider value={{ trackView, trackTrace }}>{props.children}</AppInsightsContext.Provider>;
};
