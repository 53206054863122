import { IUser } from "../context/types";
import {
  ACCESS_SETTINGS_TO_SALES_DISABLED,
  DISRIBUTOR_ORDERS_POS_PAGE_DISABLED,
  DISTRIBUTOR_ORDERS_PAGE_DISABLED,
  FORECAST_REPORT_DISABLED,
  ORDERS_SPARE_PAGE_DISABLED,
  ORDER_FORMS_ADDITIONS_PAGE_DISABLED,
  ORDER_FORMS_PAGE_DISABLED,
  ORDER_FORMS_SPARE_PAGE_DISABLED,
  POS_PAGE_DISABLED,
  PROFORMAS_PAGE_DISABLED,
  SHIPMENT_REQUESTS_PAGE_DISABLED,
  SHIPMENT_REQUESTS_POS_PAGE_DISABLED,
  UPLOAD_PRICES_PAGE_DISABLED,
  UPLOAD_SHIPMENT_SCHEDULE_PAGE_DISABLED,
  WERE_DEVICES_WERE_SOLD_DISABLED,
} from "../constants/appConstants";
import {
  checkCompany,
  checkDepartment,
  checkDepartments,
  checkPermission,
  checkPermissionBasic,
  checkSecurityGroup,
} from ".";

export interface IPermissions {
  itemsPage: boolean;
  itemsPageEditButton: boolean;
  itemsPageActionButton: boolean;
  itemPageActionButtonEdit: boolean;
  itemsPageActionButtonDownload: boolean;
  itemsPageActionButtonDelete: boolean;
  itemsPageActionButtonPrices: boolean;
  itemsPageActionButtonOrder: boolean;
  itemsPageActionButtonPricelist: boolean;
  itemsPageActionButtonUpload: boolean;
  responsibleWorkersPage: boolean;
  responsibleWorkersPageActionButton: boolean;
  priceListsPage: boolean;
  priceListsPageActionButton: boolean;
  priceListsPageActionButtonExportToExel: boolean;
  priceListNamesPage: boolean;
  priceListNamesPageActionButton: boolean;
  deliveryTermsPage: boolean;
  deliveryTermsPageActionButton: boolean;
  currenciesPage: boolean;
  currenciesPageActionButton: boolean;
  signaturesPage: boolean;
  signaturesPageActionButton: boolean;
  groupsOfProdutsPage: boolean;
  groupsOfProdutsPageActionButton: boolean;
  groupsOfItemsPageActionButton: boolean;
  groupsOfItemsPageMoveButton: boolean;
  tradeMarksPage: boolean;
  exchangeRatesUsdPage: boolean;
  exchangeRatesUsdPageActionButton: boolean;
  exchangeRatesGbpPage: boolean;
  exchangeRatesGbpPageActionButton: boolean;
  shipmentsPage: boolean;
  shipmentsPageBasicActionButton: boolean;
  shipmentsPageCertificatesActionButton: boolean;
  shipmentsPageLicensesActionButton: boolean;
  counterpartyPage: boolean;
  counterpartyPageActionButton: boolean;
  counterpartyPageAddActionButton: boolean;
  counterpartyPageAddSpecialPriceButton: boolean;
  counterpartyPageForecastButton: boolean;
  counterpartyPageAgreementsButton: boolean;
  counterpartyPageDetailsButton: boolean;
  categoriesOfItemsPageActionButton: boolean;
  customsCodesPage: boolean;
  intrastatCustomsCodesPageActionButton: boolean;
  licensePage: boolean;
  licensePageActionButton: boolean;
  rexDevicesPage: boolean;
  certificatesPage: boolean;
  contractsPage: boolean;
  orderFormsPage: boolean;
  orderFormsPageActionButton: boolean;
  orderFormsPageClearStatusesActionButton: boolean;
  ordersPage: boolean;
  ordersSparePage: boolean;
  ordersPageActionButton: boolean;
  orderFormsSparePage: boolean;
  distributorOrdersPageActionButton: boolean;
  paymentsPage: boolean;
  paymentsPageActionButton: boolean;
  shipmentRequestsPage: boolean;
  shipmentRequestsPageActionButton: boolean;
  notificationsPage: boolean;
  historyPage: boolean;
  orderFormsAdditionsPage: boolean;
  healthcheckLink: boolean;
  uploadShipmentSchedulePage: boolean;
  priceChangeNotifications: boolean;
  priceCalculationPage: boolean;
  priceCalculationEdit: boolean;
  oneTimePricesPage: boolean;
  nomenclatureToExcelPage: boolean;
  profitabilityOfCompaniesPage: boolean;
  uploadPrices: boolean;
  accessToPortalPage: boolean;
  accessSettingsToSales: boolean;
  faqPageActionButton: boolean;
  shipmentControlLogActionButton: boolean;
  posPage: boolean;
  distributorOrdersPosPage: boolean;
  shipmentRequestsPosPage: boolean;
  proformasPage: boolean;
  reports: {
    shipmentsToBuyers: boolean;
    debts: boolean;
    buyerCard: boolean;
    targets: boolean;
    quota: boolean;
    statistics: boolean;
    internalReports: boolean;
    enterTargets: boolean;
    calculationOfValues: boolean;
    cashQuota: boolean;
    productQuota: boolean;
    reportOnTheResponsible: boolean;
    executionOfOrders: boolean;
    abcAnalysis: boolean;
    wereTheDevicesWereSold: boolean;
    shipmentAmountsOfNewProducts: boolean;
    findingDeviceNumbers: boolean;
    detailedReport: boolean;
    forecast: boolean;
    assortimentAnalysis: boolean;
    shippingSchedule: boolean;
    executionOfOrdersByCounterparty: boolean;
    licenseDevices: boolean;
    serialNumbers: boolean;
    yukon: boolean;
    yukonLicenseEu001: boolean;
    shippedLicensesReport: boolean;
    forecastReport: boolean;
    pulsar: boolean;
    polaris: boolean;
    averageWeightedMarkup: boolean;
  };
}

export const getPermissionScopes = (user: IUser | null): IPermissions => ({
  itemsPage: Boolean(
    checkPermission(user, "Device.Read.All") ||
      checkPermission(user, "Device.Read.Own") ||
      checkPermission(user, "Device.ReadWrite.All") ||
      checkPermission(user, "Device.ReadWrite.Own"),
  ),
  itemsPageActionButtonDownload: Boolean(
    checkSecurityGroup(user, "aa") ||
      checkSecurityGroup(user, "bi") ||
      checkSecurityGroup(user, "ai") ||
      checkSecurityGroup(user, "am"),
  ),
  itemsPageActionButton: Boolean(
    checkDepartment(user, "s") ||
      checkDepartment(user, "m") ||
      checkDepartment(user, "q") ||
      checkDepartment(user, "p") ||
      checkDepartment(user, "i"),
  ),
  itemsPageEditButton: Boolean(
    checkPermission(user, "Device.ReadWrite.All") || checkPermission(user, "Device.ReadWrite.Own"),
  ),
  itemPageActionButtonEdit: Boolean(checkSecurityGroup(user, "as")),
  itemsPageActionButtonDelete: Boolean(checkPermission(user, "Device.ReadWrite", true)),
  itemsPageActionButtonUpload: Boolean(checkPermission(user, "Device.ImportFromFile.All")),
  itemsPageActionButtonPrices: Boolean(checkPermission(user, "Device.ManagePrices", true)),
  itemsPageActionButtonOrder: Boolean(checkPermission(user, "Device.ManageOrderForms.All")),
  itemsPageActionButtonPricelist: Boolean(checkPermission(user, "Device.ManagePriceList", true)),
  responsibleWorkersPage: Boolean(
    checkPermission(user, "ResponsibleWorker.Read.Own") ||
      checkPermission(user, "ResponsibleWorker.Read.All") ||
      checkPermission(user, "ResponsibleWorker.ReadWrite.Own") ||
      checkPermission(user, "ResponsibleWorker.ReadWrite.All"),
  ),
  responsibleWorkersPageActionButton: Boolean(
    checkPermission(user, "ResponsibleWorker.ReadWrite.Own") ||
      checkPermission(user, "ResponsibleWorker.ReadWrite.All"),
  ),
  priceListsPage: Boolean(
    checkPermission(user, "Pricelists.Read.Own") ||
      checkPermission(user, "Pricelists.ReadWrite.Own") ||
      checkPermission(user, "Pricelists.Read.All") ||
      checkPermission(user, "Pricelists.ReadWrite.All"),
  ),
  priceListsPageActionButton: Boolean(checkPermission(user, "Pricelists.ManagePricelists.All")),
  priceListsPageActionButtonExportToExel: Boolean(checkPermission(user, "Pricelists.ExportExcel.All")),
  priceListNamesPage: Boolean(
    checkPermission(user, "PricelistName.Read.Own") ||
      checkPermission(user, "PricelistName.ReadWrite.Own") ||
      checkPermission(user, "PricelistName.Read.All") ||
      checkPermission(user, "PricelistName.ReadWrite.All"),
  ),
  priceListNamesPageActionButton: Boolean(
    checkPermission(user, "PricelistName.ReadWrite.Own") || checkPermission(user, "PricelistName.ReadWrite.All"),
  ),
  deliveryTermsPage: Boolean(
    checkPermission(user, "TermOfDelivery.Read.Own") ||
      checkPermission(user, "TermOfDelivery.ReadWrite.Own") ||
      checkPermission(user, "TermOfDelivery.Read.All") ||
      checkPermission(user, "TermOfDelivery.ReadWrite.All"),
  ),
  deliveryTermsPageActionButton: Boolean(
    checkPermission(user, "TermOfDelivery.ReadWrite.All") || checkPermission(user, "TermOfDelivery.ReadWrite.Own"),
  ),
  currenciesPage: Boolean(
    checkPermission(user, "Currency.Read.Own") ||
      checkPermission(user, "Currency.ReadWrite.Own") ||
      checkPermission(user, "Currency.Read.All") ||
      checkPermission(user, "Currency.ReadWrite.All"),
  ),
  currenciesPageActionButton: Boolean(
    checkPermission(user, "Currency.ReadWrite.Own") || checkPermission(user, "Currency.ReadWrite.All"),
  ),
  signaturesPage: Boolean(
    checkPermission(user, "Signatures.Read.Own") ||
      checkPermission(user, "Signatures.ReadWrite.Own") ||
      checkPermission(user, "Signatures.Read.All") ||
      checkPermission(user, "Signatures.ReadWrite.All"),
  ),
  signaturesPageActionButton: Boolean(checkPermission(user, "Signatures.ReadWrite.All")),
  groupsOfProdutsPage: Boolean(
    checkPermission(user, "DeviceProductGroup.Read.All") ||
      checkPermission(user, "DeviceProductGroup.Read.Own") ||
      checkPermission(user, "DeviceProductGroup.ReadWrite.All") ||
      checkPermission(user, "DeviceProductGroup.ReadWrite.Own"),
  ),
  groupsOfProdutsPageActionButton: Boolean(
    checkPermission(user, "DeviceProductGroup.ReadWrite.All") ||
      checkPermission(user, "DeviceProductGroup.ReadWrite.Own"),
  ),
  groupsOfItemsPageActionButton: Boolean(checkPermission(user, "DeviceGroup.ReadWrite.All")),
  groupsOfItemsPageMoveButton: Boolean(
    checkPermission(user, "DeviceGroup.ReadWrite.All") || checkSecurityGroup(user, "as"),
  ),
  tradeMarksPage: Boolean(
    checkSecurityGroup(user, "AA") || checkSecurityGroup(user, "AI") || checkSecurityGroup(user, "BI"),
  ),
  exchangeRatesUsdPage: Boolean(
    checkPermission(user, "USDRates.Read.Own") ||
      checkPermission(user, "USDRates.ReadWrite.Own") ||
      checkPermission(user, "USDRates.Read.All") ||
      checkPermission(user, "USDRates.ReadWrite.All"),
  ),
  exchangeRatesUsdPageActionButton: Boolean(checkPermission(user, "USDRates.ReadWrite.All")),
  exchangeRatesGbpPage: Boolean(checkPermission(user, "License.ReadWrite.All")),
  exchangeRatesGbpPageActionButton: Boolean(checkPermission(user, "License.ReadWrite.All")),
  shipmentsPage: Boolean(
    checkPermissionBasic(user, "Shipments.Read.All") ||
      checkPermissionBasic(user, "Shipments.Read.Own") ||
      checkPermissionBasic(user, "Shipments.ReadWrite.All") ||
      checkPermissionBasic(user, "Shipments.ReadWrite.Own"),
  ),
  shipmentsPageBasicActionButton: Boolean(
    checkPermissionBasic(user, "Shipments.ReadWrite.All") || checkPermissionBasic(user, "Shipments.ReadWrite.Own"),
  ),
  shipmentsPageCertificatesActionButton: Boolean(
    checkSecurityGroup(user, "AA") || checkCompany(user, "L") || checkDepartment(user, "S"),
  ),
  shipmentsPageLicensesActionButton: Boolean(checkSecurityGroup(user, "AP")),
  counterpartyPage: Boolean(
    checkPermission(user, "Counterparty.Read.Own") ||
      checkPermission(user, "Counterparty.ReadWrite.Own") ||
      checkPermission(user, "Counterparty.Read.All") ||
      checkPermission(user, "Counterparty.ReadWrite.All"),
  ),
  counterpartyPageActionButton: Boolean(checkPermission(user, "Counterparty.ReadWrite.All")),
  counterpartyPageAddActionButton: Boolean(
    checkPermission(user, "Counterparty.ReadWrite.All") && (checkCompany(user, "a") || checkCompany(user, "b")),
  ),
  counterpartyPageAddSpecialPriceButton: Boolean(checkPermission(user, "Counterparty.ReadWrite.All", true)),
  counterpartyPageAgreementsButton: Boolean(checkPermission(user, "Counterparty.ManageAgreements.All")),
  counterpartyPageDetailsButton: Boolean(
    checkPermission(user, "Counterparty.ReadWrite.Own") ||
      checkPermission(user, "Counterparty.Read.All") ||
      checkPermission(user, "Counterparty.ReadWrite.All"),
  ),
  counterpartyPageForecastButton: !Boolean(
    checkCompany(user, "m") || checkCompany(user, "y") || checkCompany(user, "p"),
  ),
  categoriesOfItemsPageActionButton: Boolean(
    checkPermission(user, "DeviceCategory.ReadWrite.All") || checkPermission(user, "DeviceCategory.ReadWrite.Own"),
  ),
  customsCodesPage: Boolean(
    checkPermission(user, "CustomsCode.Read.Own") ||
      checkPermission(user, "CustomsCode.ReadWrite.Own") ||
      checkPermission(user, "CustomsCode.Read.All") ||
      checkPermission(user, "CustomsCode.ReadWrite.All"),
  ),
  intrastatCustomsCodesPageActionButton: Boolean(checkCompany(user, "y") || checkSecurityGroup(user, "aa")),
  licensePage: Boolean(
    checkPermission(user, "License.Read.Own") ||
      checkPermission(user, "License.ReadWrite.Own") ||
      checkPermission(user, "License.Read.All") ||
      checkPermission(user, "License.ReadWrite.All"),
  ),
  licensePageActionButton: Boolean(checkPermission(user, "License.ReadWrite.All")),
  rexDevicesPage: true,
  certificatesPage: Boolean(checkCompany(user, "l") || checkSecurityGroup(user, "aa") || checkDepartment(user, "s")),
  contractsPage: Boolean(
    checkPermissionBasic(user, "Contracts.Read.All") ||
      checkPermissionBasic(user, "Contracts.Read.Own") ||
      checkPermissionBasic(user, "Contracts.ReadWrite.All") ||
      checkPermissionBasic(user, "Contracts.ReadWrite.Own"),
  ),
  orderFormsPage:
    !ORDER_FORMS_PAGE_DISABLED &&
    Boolean(
      checkPermissionBasic(user, "OrderForms.Read.Own") ||
        checkPermissionBasic(user, "OrderForms.Read.All") ||
        checkPermissionBasic(user, "OrderForms.ReadWrite.Own") ||
        checkPermissionBasic(user, "OrderForms.ReadWrite.All") ||
        checkSecurityGroup(user, "ym") ||
        checkSecurityGroup(user, "bm"),
    ),
  orderFormsPageActionButton: Boolean(
    checkPermissionBasic(user, "OrderForms.ReadWrite.All") &&
      (checkSecurityGroup(user, "aa") ||
        checkSecurityGroup(user, "ai") ||
        checkSecurityGroup(user, "bi") ||
        checkSecurityGroup(user, "am")),
  ),
  orderFormsSparePage: !ORDER_FORMS_SPARE_PAGE_DISABLED,
  orderFormsPageClearStatusesActionButton: Boolean(checkPermissionBasic(user, "OrderForms.ReadWrite.All")),
  ordersPage:
    !DISTRIBUTOR_ORDERS_PAGE_DISABLED &&
    Boolean(
      checkPermissionBasic(user, "Orders.Read.Own") ||
        checkPermissionBasic(user, "Orders.ReadWrite.Own") ||
        checkPermissionBasic(user, "Orders.Read.All") ||
        checkPermissionBasic(user, "Orders.ReadWrite.All"),
    ),
  ordersSparePage: !ORDERS_SPARE_PAGE_DISABLED,
  ordersPageActionButton: !Boolean(checkDepartment(user, "m") && user?.departments.length === 1),
  distributorOrdersPageActionButton: Boolean(
    checkPermissionBasic(user, "Orders.ReadWrite.Own") || checkPermissionBasic(user, "Orders.ReadWrite.All"),
  ),
  paymentsPage: Boolean(
    checkPermissionBasic(user, "Payments.Read.All") ||
      checkPermissionBasic(user, "Payments.Read.Own") ||
      checkPermissionBasic(user, "Payments.ReadWrite.Own") ||
      checkPermissionBasic(user, "Payments.ReadWrite.All"),
  ),
  paymentsPageActionButton: Boolean(
    checkPermissionBasic(user, "Payments.Read.All") ||
      checkPermissionBasic(user, "Payments.ReadWrite.Own") ||
      checkPermissionBasic(user, "Payments.ReadWrite.All"),
  ),
  shipmentRequestsPage:
    !SHIPMENT_REQUESTS_PAGE_DISABLED &&
    Boolean(
      checkPermissionBasic(user, "ShipmentRequest.Read.Own") ||
        checkPermissionBasic(user, "ShipmentRequest.Read.All") ||
        checkPermissionBasic(user, "ShipmentRequest.ReadWrite.Own") ||
        checkPermissionBasic(user, "ShipmentRequest.ReadWrite.All"),
    ),
  shipmentRequestsPageActionButton: Boolean(
    checkPermissionBasic(user, "ShipmentRequest.ReadWrite.Own") ||
      checkPermissionBasic(user, "ShipmentRequest.ReadWrite.All"),
  ),
  notificationsPage: Boolean(checkCompany(user, "A") || checkDepartment(user, "A")),
  historyPage: Boolean(checkCompany(user, "A") && checkDepartment(user, "A")),
  orderFormsAdditionsPage:
    !ORDER_FORMS_ADDITIONS_PAGE_DISABLED &&
    Boolean(checkSecurityGroup(user, "aa") || checkSecurityGroup(user, "ai") || checkSecurityGroup(user, "bi")),
  uploadShipmentSchedulePage:
    !UPLOAD_SHIPMENT_SCHEDULE_PAGE_DISABLED &&
    Boolean(checkSecurityGroup(user, "aa") || checkSecurityGroup(user, "as") || checkSecurityGroup(user, "ai")),
  priceChangeNotifications: Boolean(checkSecurityGroup(user, "aa") || checkSecurityGroup(user, "am")),
  healthcheckLink: Boolean(checkSecurityGroup(user, "aa")),
  priceCalculationPage: Boolean(
    (checkPermissionBasic(user, "PriceFormation.Read.Own") ||
      checkPermissionBasic(user, "PriceFormation.Read.All") ||
      checkPermissionBasic(user, "PriceFormation.ReadWrite.Own") ||
      checkPermissionBasic(user, "PriceFormation.ReadWrite.All")) &&
      checkDepartments(user, ["a", "p", "q", "m"]),
  ),
  priceCalculationEdit: Boolean(
    (checkPermissionBasic(user, "PriceFormation.ReadWrite.Own") ||
      checkPermissionBasic(user, "PriceFormation.ReadWrite.All")) &&
      checkDepartments(user, ["a", "p", "q", "m"]),
  ),
  oneTimePricesPage: Boolean(
    checkPermissionBasic(user, "PriceFormation.Read.All") ||
      checkPermissionBasic(user, "PriceFormation.ReadWrite.All") ||
      checkPermissionBasic(user, "PriceFormation.ReadWrite.Own"),
  ),
  nomenclatureToExcelPage: Boolean(
    checkPermissionBasic(user, "PriceFormation.Read.All") ||
      checkPermissionBasic(user, "PriceFormation.ReadWrite.All") ||
      checkPermissionBasic(user, "PriceFormation.ReadWrite.Own"),
  ),
  profitabilityOfCompaniesPage: Boolean(
    checkPermissionBasic(user, "PriceFormation.Read.All") ||
      checkPermissionBasic(user, "PriceFormation.Read.Own") ||
      checkPermissionBasic(user, "PriceFormation.ReadWrite.All") ||
      checkPermissionBasic(user, "PriceFormation.ReadWrite.Own"),
  ),
  accessToPortalPage: Boolean(checkSecurityGroup(user, "aa") || checkSecurityGroup(user, "bi")),
  accessSettingsToSales: !ACCESS_SETTINGS_TO_SALES_DISABLED && Boolean(checkSecurityGroup(user, "aa")),
  uploadPrices:
    !UPLOAD_PRICES_PAGE_DISABLED && Boolean(checkSecurityGroup(user, "aa") || checkSecurityGroup(user, "am")),
  faqPageActionButton: Boolean(checkSecurityGroup(user, "aa")),
  shipmentControlLogActionButton: Boolean(checkSecurityGroup(user, "aa")),
  posPage: !POS_PAGE_DISABLED,
  distributorOrdersPosPage: !DISRIBUTOR_ORDERS_POS_PAGE_DISABLED,
  shipmentRequestsPosPage: !SHIPMENT_REQUESTS_POS_PAGE_DISABLED,
  proformasPage: !PROFORMAS_PAGE_DISABLED,
  reports: {
    shipmentsToBuyers: Boolean(
      checkPermissionBasic(user, "Reports::Rep7.Read.Own") || checkPermissionBasic(user, "Reports::Rep7.Read.All"),
    ),
    debts: Boolean(
      checkPermissionBasic(user, "Reports::Rep0.Read.Own") || checkPermissionBasic(user, "Reports::Rep0.Read.All"),
    ),
    buyerCard: true,
    targets: Boolean(checkSecurityGroup(user, "aa") || checkSecurityGroup(user, "ad")),
    quota: Boolean(
      checkPermissionBasic(user, "Reports::Rep1.Read.Own") || checkPermissionBasic(user, "Reports::Rep1.Read.All"),
    ),
    enterTargets: true,
    internalReports: Boolean(
      checkPermissionBasic(user, "Reports::Rep2.Read.Own") || checkPermissionBasic(user, "Reports::Rep2.Read.All"),
    ),
    calculationOfValues: true,
    cashQuota: true,
    productQuota: true,
    reportOnTheResponsible: Boolean(
      checkPermissionBasic(user, "Reports::Rep1.Read.Own") || checkPermissionBasic(user, "Reports::Rep1.Read.All"),
    ),
    executionOfOrders: Boolean(
      checkPermissionBasic(user, "Reports::Rep0.Read.Own") || checkPermissionBasic(user, "Reports::Rep0.Read.All"),
    ),
    abcAnalysis: Boolean(
      checkPermissionBasic(user, "Reports::Rep0.Read.Own") || checkPermissionBasic(user, "Reports::Rep0.Read.All"),
    ),
    wereTheDevicesWereSold:
      !WERE_DEVICES_WERE_SOLD_DISABLED &&
      Boolean(
        checkPermissionBasic(user, "Reports::Rep6.Read.Own") || checkPermissionBasic(user, "Reports::Rep6.Read.All"),
      ),
    shipmentAmountsOfNewProducts: Boolean(
      checkPermissionBasic(user, "Reports::Rep0.Read.Own") || checkPermissionBasic(user, "Reports::Rep0.Read.All"),
    ),
    findingDeviceNumbers: Boolean(
      checkPermissionBasic(user, "Reports::Rep5.Read.Own") || checkPermissionBasic(user, "Reports::Rep5.Read.All"),
    ),
    detailedReport: Boolean(
      checkPermissionBasic(user, "Reports::Rep3.Read.Own") || checkPermissionBasic(user, "Reports::Rep3.Read.All"),
    ),
    forecast: Boolean(
      checkPermissionBasic(user, "Reports::Rep0.Read.Own") || checkPermissionBasic(user, "Reports::Rep0.Read.All"),
    ),
    assortimentAnalysis: true,
    statistics: Boolean(
      checkPermissionBasic(user, "Reports::Rep3.Read.Own") || checkPermissionBasic(user, "Reports::Rep3.Read.All"),
    ),
    shippingSchedule: Boolean(
      checkSecurityGroup(user, "aa") || checkSecurityGroup(user, "ad") || checkDepartments(user, ["s", "i"]),
    ),
    executionOfOrdersByCounterparty: Boolean(
      checkSecurityGroup(user, "aa") || checkSecurityGroup(user, "ad") || checkDepartments(user, ["s", "i"]),
    ),
    licenseDevices: Boolean(
      checkSecurityGroup(user, "AA") ||
        checkSecurityGroup(user, "AD") ||
        checkDepartment(user, "S") ||
        checkDepartment(user, "I"),
    ),
    serialNumbers: true,
    yukon: Boolean(
      checkPermissionBasic(user, "Reports::Yukon.Read.Own") || checkPermissionBasic(user, "Reports::Yukon.Read.All"),
    ),
    yukonLicenseEu001: true,
    shippedLicensesReport: Boolean(
      checkPermissionBasic(user, "Reports::Rep2.Read.Own") || checkPermissionBasic(user, "Reports::Rep2.Read.All"),
    ),
    forecastReport:
      !FORECAST_REPORT_DISABLED &&
      Boolean(
        checkPermissionBasic(user, "Reports::Rep0.Read.Own") || checkPermissionBasic(user, "Reports::Rep0.Read.All"),
      ),
    pulsar: Boolean(
      checkPermissionBasic(user, "Reports::Pulsar.Read.Own") || checkPermissionBasic(user, "Reports::Pulsar.Read.All"),
    ),
    polaris: Boolean(
      checkPermissionBasic(user, "Reports::Polaris.Read.Own") ||
        checkPermissionBasic(user, "Reports::Polaris.Read.All"),
    ),
    averageWeightedMarkup: Boolean(
      checkSecurityGroup(user, "AA") || checkSecurityGroup(user, "AD") || checkDepartment(user, "M")
    )
  },
});
