import { findIndex, indexOf, last, map, uniq } from "lodash-es";
import { Keydown } from "../constants/enums";

export const onKeydown = (e: any) => {
  if (e.key === Keydown.ArrowUp || e.key === Keydown.ArrowDown || e.key === Keydown.Tab) {
    const layers = document.querySelectorAll(".ms-Layer");
    const activeLayer = layers.length ? layers[layers.length - 1] : document;
    const inputs = activeLayer.querySelectorAll(`.ms-DetailsList input[name="${e.target.name}"]`);
    const input = e.target.localName === "input" ? e.target : e.target.querySelector(`input[name="${e.target.name}"]`);
    const index = findIndex(inputs, input);

    if (e.key === Keydown.Tab) {
      const allInputs: any = activeLayer.querySelectorAll(`.ms-DetailsList input`);
      const columnNames = uniq(map(allInputs, (input: any) => input.name));
      const nameIndex = indexOf(columnNames, e.target.name);
      const name = allInputs[!e.shiftKey ? nameIndex + 1 : nameIndex - 1]?.name;
      const targetInput: any = activeLayer.querySelectorAll(`.ms-DetailsList input[name="${name}"]`)[index];
      setTimeout(() => {
        targetInput?.focus();
        const end = targetInput.value.length;
        targetInput.type = "text";
        targetInput.setSelectionRange(end, end);
        targetInput.type = "number";
      }, 0);
      return;
    }

    const targetInput: any = inputs[e.key === Keydown.ArrowUp ? index - 1 : index + 1];
    setTimeout(() => targetInput?.focus(), 0);
  }
};

export const onKeydownEvent = (e: any) => {
  if (e.key === Keydown.ArrowUp || e.key === Keydown.ArrowDown) {
    e.preventDefault();
    e.stopPropagation();
  } else if (e.key === Keydown.ArrowLeft || e.key === Keydown.ArrowRight) {
    e.stopPropagation();
  }
};

export const onPressEnter = (e: any) => {
  if (e.key === Keydown.Enter) {
    const element = getLayer() || document;
    const primaryButton: any = last(element?.querySelectorAll(".ms-Button.ms-Button--primary"));
    primaryButton?.click();
  }
};

export const onWheel = (e: any) => e.currentTarget.blur();

export const getLayer = () => {
  const layers = document.querySelectorAll(".ms-Layer");
  const doc = layers[layers.length - 1];
  return doc;
};
