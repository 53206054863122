import React, { useContext, useEffect } from "react";
import {
  DASHBOARD_EXPIRING_CONTRACTS_ENDPOINT,
  DASHBOARD_EXPIRING_LICENSES_ENDPOINT,
  DASHBOARD_LICENSES_WITH_ENDING_POSITION_OUT_ENDPOINT,
} from "../../../constants/endpoints";
import { LanguageContext } from "../../../context/languageContext";
import { AppInsightsContext } from "../../../context/appInsightsContext";
import { AuthContext } from "../../../context/authContext";
import { PageWrapper } from "../../wrappers/PageWrapper";
import { Widget } from "../../common/Widget";
import "./styles.scss";

export const DashboardPage = () => {
  const { loc } = useContext(LanguageContext);
  const { user } = useContext(AuthContext);
  const { trackView } = useContext(AppInsightsContext);
  useEffect(() => {
    trackView(user, "Dashboard page has been visited");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <PageWrapper>
      <div className="dashboard-page container">
        <div className="widgets">
          <Widget
            type={loc.menu.licenses}
            title={loc.licensesPage.options.expiresSoon}
            link="/operations/licenses/expiring"
            backgroundColor="#4cb5ab"
            endpoint={DASHBOARD_EXPIRING_LICENSES_ENDPOINT}
          />
          <Widget
            type={loc.menu.licenses}
            title={loc.licensesPage.options.runningOut}
            link="/operations/licenses/withEndingPositions"
            backgroundColor="#4cb5ab"
            endpoint={DASHBOARD_LICENSES_WITH_ENDING_POSITION_OUT_ENDPOINT}
          />
          <Widget
            type={loc.menu.contracts}
            title={loc.licensesPage.options.expiresSoon}
            link="/operations/contracts/expiring"
            backgroundColor="#4cb5ab"
            endpoint={DASHBOARD_EXPIRING_CONTRACTS_ENDPOINT}
          />
        </div>
      </div>
    </PageWrapper>
  );
};
