import React, { useContext, useState, useEffect } from "react";
import { map } from "lodash-es";
import { Callout, DirectionalHint, FontIcon, TooltipHost } from "@fluentui/react";
import loc from "../../../localization";
import { IAlert } from "../../../typings";
import { APP_ENV, APP_VERSION } from "../../../constants/appConstants";
import { AppLanguage } from "../../../constants/enums";
import { AuthContext } from "../../../context/authContext";
import { LanguageContext } from "../../../context/languageContext";
import "./styles.scss";

export const Navbar = () => {
  const jsonAlerts = sessionStorage.getItem("alerts");
  const alertsArr = jsonAlerts ? JSON.parse(jsonAlerts) : [];
  const { name, isAuthenticated, handleLogout } = useContext(AuthContext);
  const { language, changeAppLanguage } = useContext(LanguageContext);
  const [alerts, setAlerts] = useState<IAlert[]>(alertsArr);
  const [alertsIsVisible, setAlertsIsVisible] = useState<boolean>(false);
  const toggleAlertsIsVisible = () => setAlertsIsVisible(!alertsIsVisible);
  const copyToClipboard = (alert: IAlert) => {
    const copied = `
			Error code: ${alert.errorCode};
			Message: ${alert.message};
			CorrelationId: ${alert.correlationId};
		`;
    navigator.clipboard.writeText(copied);
  };
  const onClear = () => {
    setAlerts([]);
    sessionStorage.removeItem("alerts");
  };
  useEffect(() => {
    setAlerts(alertsArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertsArr.length]);
  return (
    <nav className="navbar">
      <div className="navbar__title">
        Yukon - {loc.title} {APP_ENV?.length ? `- ${APP_ENV}` : ""} - v{APP_VERSION}
      </div>
      {isAuthenticated && (
        <div className="navbar__user">
          <div className="navbar__lang">
            <ul className="navbar__lang-list">
              <li
                className={language === AppLanguage.English ? `active-lang` : ""}
                onClick={() => changeAppLanguage(AppLanguage.English)}
              >
                En
              </li>
              <li
                className={language === AppLanguage.Russian ? `active-lang` : ""}
                onClick={() => changeAppLanguage(AppLanguage.Russian)}
              >
                Ru
              </li>
            </ul>
          </div>
          {alerts.length ? (
            <div className="navbar__alerts">
              <TooltipHost content={loc.tooltipContent.receivedErrors} directionalHint={DirectionalHint.rightCenter}>
                <FontIcon id="alert-icon" iconName="Error" onClick={toggleAlertsIsVisible} />
                <div className="count">{alerts.length}</div>
              </TooltipHost>
              {alertsIsVisible && (
                <Callout
                  className="alerts-callout"
                  target="#alert-icon"
                  isBeakVisible={false}
                  setInitialFocus
                  directionalHint={DirectionalHint.topLeftEdge}
                  onDismiss={toggleAlertsIsVisible}
                >
                  <span className="clear" onClick={onClear}>
                    {loc.buttons.clear}
                  </span>
                  {map(alerts, (alert) => (
                    <div className="alert" key={alert.correlationId}>
                      <div className="column">
                        <span className="time">{alert.time}</span>
                        <TooltipHost content={loc.tooltipContent.copy}>
                          <FontIcon iconName="copy" onClick={() => copyToClipboard(alert)} />
                        </TooltipHost>
                      </div>
                      <div className="column">
                        <div className="date">{alert.date}</div>
                        <div className="title">Error code: "{alert.errorCode}"</div>
                        <div className="message">Message: "{alert.message}"</div>
                      </div>
                    </div>
                  ))}
                </Callout>
              )}
            </div>
          ) : null}
          <div className="navbar__user-name">{name}</div>
          <button onClick={handleLogout}>{loc.signOut}</button>
        </div>
      )}
    </nav>
  );
};
