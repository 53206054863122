import React from "react";
import { AppInsightsContext } from "../../../context/appInsightsContext";

interface IErrorBoundaryProps {
  forceReload?: boolean;
  children: any;
}

interface IErrorBoundaryState {
  hasError: boolean;
  error: string;
}

export class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  static contextType = AppInsightsContext;

  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: "" };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error: error?.toString() };
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (this.props.forceReload) window.location.reload();
  }

  render() {
    if (this.state.hasError) {
      this.context.trackTrace(null, `${this.state.error} on ${window.location.pathname}`, { user: "" });
      return <h5 style={{ marginLeft: 20 }}>{this.state.error}</h5>;
    }
    return this.props.children;
  }
}
