import React from "react";
import { Spinner, SpinnerSize } from "@fluentui/react";
import "./styles.scss";

type LoadingProps = {
  children: JSX.Element;
  isLoading: boolean;
};

export const Loading = (props: LoadingProps) =>
  props.isLoading ? (
    <div className="loading-block">
      {props.children}
      <Spinner size={SpinnerSize.large} />
    </div>
  ) : (
    props.children
  );
