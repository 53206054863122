import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { initializeIcons, loadTheme } from "@fluentui/react";
import ReactNotification from "react-notifications-component";
import { ApplicationRouter } from "./router/router";
import { AppInsightsContextProvider } from "./context/appInsightsContext";
import { AuthContextProvider } from "./context/authContext";
import { LanguageContextProvider } from "./context/languageContext";
import { CheckUpdatesContextProvider } from "./context/checkUpdatesContext";
import { SignInPage } from "./components/pages/SignInPage";
import { ErrorBoundary } from "./components/wrappers/ErrorBoundary";
import { msalConfig } from "./authConfig";
import "./styles/index.scss";

const msalInstance = new PublicClientApplication(msalConfig);

const App = () => {
  useEffect(() => {
    initializeIcons(undefined, { disableWarnings: true });
    loadTheme({ palette: { themePrimary: "#0f8387", themeDark: "#324c4d" } });
    document.title = `${document.title} ${process.env.REACT_APP_ENV}`;
  }, []);
  return (
    <div className="app">
      <AuthenticatedTemplate>
        <AppInsightsContextProvider>
          <ErrorBoundary forceReload={true}>
            <AuthContextProvider>
              <LanguageContextProvider>
                <CheckUpdatesContextProvider>
                  <ReactNotification />
                  <ErrorBoundary forceReload={true}>
                    <ApplicationRouter />
                  </ErrorBoundary>
                </CheckUpdatesContextProvider>
              </LanguageContextProvider>
            </AuthContextProvider>
          </ErrorBoundary>
        </AppInsightsContextProvider>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <SignInPage />
      </UnauthenticatedTemplate>
    </div>
  );
};

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>,
  document.getElementById("root"),
);
