import { isArray, map } from "lodash-es";
import { MessageBar, MessageBarType } from "@fluentui/react";
import "./styles.scss";

interface IErrorsBlockProps {
  errors: string | string[];
  warningStyle?: boolean;
}

export const ErrorsBlock = ({ errors, warningStyle }: IErrorsBlockProps) => {
  return errors.length ? (
    <div className="errors-block">
      {isArray(errors) ? (
        map(errors, (error) => (
          <MessageBar key={error} messageBarType={warningStyle ? MessageBarType.warning : MessageBarType.error}>
            {error}
          </MessageBar>
        ))
      ) : (
        <MessageBar messageBarType={warningStyle ? MessageBarType.warning : MessageBarType.error}>{errors}</MessageBar>
      )}
    </div>
  ) : null;
};
