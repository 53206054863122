import { decode } from "js-base64";
import { store } from "react-notifications-component";
import { IDropdownOption } from "@fluentui/react";
import { format as rublesFormat } from "@vicimpa/rubles";
import { map, filter, isNull, find } from "lodash-es";
import { differenceInCalendarDays, format, parse } from "date-fns";
import { Notification } from "../constants/enums";
import { emptyDatePicker } from "../constants/strings";
import { IUser } from "../context/types";
import loc from "../localization";
import { IPermissions } from "./getPermissionScopes";

export const getNotification = (title: string, message: string, type: Notification) =>
  store.addNotification({
    title,
    message,
    type,
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration: 3000,
    },
  });

export const transformDateFormat = (string: string): string => {
  const date = transformStringToDate(string);
  return date ? format(date, "yyyy-MM-dd") : "";
};

export const transformMonthFormat = (string: string): string => {
  const date = transformStringToDateFormatMonth(string);
  return date ? format(date, "yyyy-MM-dd") : "";
};

export const transformStringToDateWithTime = (string: Date) => format(new Date(string), "dd/MM/yyyy HH:mm") || null;

export const transformStringToDate = (string: string): Date | null => {
  return parse(string, "dd/MM/yyyy", new Date().setHours(0, 0, 0, 0)).toString() !== "Invalid Date"
    ? parse(string, "dd/MM/yyyy", new Date().setHours(0, 0, 0, 0))
    : null;
};

export const transformStringToDateFormatMonth = (string: string): Date | null => {
  return parse(string, "MM/yyyy", new Date().setHours(0, 0, 0, 0)).toString() !== "Invalid Date"
    ? parse(string, "MM/yyyy", new Date().setHours(0, 0, 0, 0))
    : null;
};

export const transformDateToString = (date: Date | null | undefined) => {
  return !date ? "" : format(new Date(date), "dd/MM/yyyy");
};

export const transformDateWithoutTime = (date: Date) => date?.setHours(0, 0, 0, 0);

export const validateDateRange = (values: any) => {
  const errors: any = {};
  const date_s = transformStringToDate(values.date_s);
  if (!!values.date_s && isNull(date_s)) {
    errors.date_s = loc.warnings.invalidDate;
  }

  if (!!values.date_po) {
    const date_po = transformStringToDate(values.date_po);
    if (!date_po) {
      errors.date_po = loc.warnings.invalidDate;
      return errors;
    }
    if (date_s! >= date_po) {
      errors.date_po = loc.warnings.mustBeBiggerThenPeriodFrom;
    }
  }
  return errors;
};

export const validateDateRangeOfReplacement = (values: any) => {
  const errors: any = {};
  const date_s = transformStringToDate(values.date_s);
  if (!!values.date_s && isNull(date_s)) {
    errors.date_s = loc.warnings.invalidDate;
  }

  if (!!values.date_po) {
    const date_po = transformStringToDate(values.date_po);
    if (!date_po) {
      errors.date_po = loc.warnings.invalidDate;
      return errors;
    }
    if (date_s! > date_po) {
      errors.date_po = loc.warnings.mustBeBiggerThenPeriodFrom;
    }
  }
  return errors;
};

export const validateDateRangeOfApproval = (values: any) => {
  const errors: any = {};
  const d_cen = transformStringToDate(values.d_cen);
  if (!!values.d_cen && isNull(d_cen)) {
    errors.d_cen = loc.warnings.invalidDate;
  }
  return errors;
};

export const getCurrencyErrorMessage = (values: any) => {
  const errors: any = {};
  if (isNull(values.id_val)) {
    errors.id_val = loc.warnings.noSelectedCurrency;
  }
  return errors;
};

export const validateDatePicker = (firstDate?: string, secondDate?: string) => {
  const errors: any = {};

  if (firstDate) {
    const dat1 = transformStringToDate(firstDate);
    if (isNull(dat1)) {
      errors.dat1 = loc.warnings.invalidDate;
    }
    if (firstDate === emptyDatePicker) {
      errors.dat1 = loc.warnings.requiredField;
    }
  }
  if (secondDate) {
    const dat2 = transformStringToDate(secondDate);
    if (isNull(dat2)) {
      errors.dat2 = loc.warnings.invalidDate;
    }
    if (secondDate === emptyDatePicker) {
      errors.dat2 = loc.warnings.requiredField;
    }
  }
  return errors;
};

export const validateOneResponsibleRequired = (values: any) => {
  const errors: any = {};
  if (!values.id_oi_sbyt && !values.id_oi_so) {
    errors.id_oi_so = loc.responsiblePersonsPage.panel.errors.oneFieldRequired;
    errors.id_oi_sbyt = loc.responsiblePersonsPage.panel.errors.oneFieldRequired;
  }
  return errors;
};

export const checkDepartment = (user: IUser | null, department: string) => {
  return user?.departments.map((item) => item.toLowerCase()).includes(department.toLowerCase());
};

export const checkDepartments = (user: IUser | null, departments: string[]): boolean => {
  return !!departments.filter((d) => checkDepartment(user, d))[0];
};

export const checkCompany = (user: IUser | null, company: string) => {
  return user?.companies.map((item) => item.toLowerCase()).includes(company.toLowerCase());
};

export const checkSecurityGroup = (user: IUser | null, securityGroup: string) => {
  return user?.securityGroups.map((item) => item.toLowerCase()).includes(securityGroup.toLowerCase());
};

export const checkPermission = (user: IUser | null, permission: string, withoutRole?: boolean) => {
  return withoutRole
    ? map(
        filter(
          map(user?.permissionScopes, (item) => item.split("::")[1]),
          (item) => !!item,
        ),
        (item) => item.split(".").slice(0, 2).join("."),
      ).includes(permission)
    : filter(
        map(user?.permissionScopes, (item) => item.split("::")[1]),
        (item) => !!item,
      ).includes(permission);
};

export const checkPermissionBasic = (user: IUser | null, permission: string) => {
  return map(user?.permissionScopes, (item) => {
    const newItem = item.split(".").slice(1).join(".");
    return newItem;
  }).includes(permission);
};

export const getLcid = (loc: any) => {
  return loc._language === "ru" ? 1049 : 1033;
};

export const getPriceText = (price: number) => {
  return rublesFormat(price.toFixed(2), "$summString $summCurrency $penny $pennyCurrency");
};

export const getPdf = (response: any) => {
  const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
  const iframe = document.createElement("iframe");
  document.body.appendChild(iframe);
  iframe.style.display = "none";
  iframe.src = url;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe.contentWindow?.print();
    }, 0);
  };
};

export const downloadFile = (response: any, fileName: string, format?: string) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  const decodedFileName = decode(response.headers["content-disposition"].split("filename=")[1]);

  const name = decodedFileName || `${fileName}.${format || "xlsx"}`;
  link.href = url;
  link.download = name;
  // document.body.appendChild(link);
  link.click();
};

export const uploadFile = (file: any) => {
  const formData = new FormData();
  formData.append("file", file);
  return formData;
};

export const getMenuPropsItems = (
  permissions: IPermissions,
  selectedItem: any,
  getActions: any,
  setActiveCommand: any,
) => {
  return map(
    filter(getActions(permissions, selectedItem).slice(1), (action) => !action.disabled),
    (action) => ({
      key: action.text,
      text: action.text,
      disabled: action.disabled,
      onClick: action.command ? () => setActiveCommand(action.command) : () => {},
      iconProps: { iconName: action.iconName },
      items: !!action.items
        ? map(action.items, (action) => ({
            key: action.text,
            text: action.text,
            disabled: action.disabled,
            onClick: () => setActiveCommand(action.command),
          }))
        : undefined,
    }),
  );
};

export const addUndefinedOption = (options: IDropdownOption[]) => [
  { key: 0, text: loc.main.undefinedOption },
  ...options,
];

export const isFullDate = (date: string) => date.length === 10 && !date.includes("_");

export const checkDateError = (date: string) => isFullDate(date) && !transformStringToDate(date) && "Invalid date";

export const getDateFilterTitle = (dateFrom: string, dateTo: string, defaultPeriodTitle: string) =>
  transformStringToDate(dateFrom) || transformStringToDate(dateTo)
    ? `${isFullDate(dateFrom) ? dateFrom : "-"} | ${isFullDate(dateTo) ? `${dateTo}` : "-"}`
    : defaultPeriodTitle;

export const filterRecipients = ({ gr1, gr2, gr3 }: any, recipients: any) => {
  if (gr1 + gr2 + gr3 === 0) {
    return recipients;
  } else if (gr1 > 0 && gr2 + gr3 === 0) {
    return filter(recipients, { gr1 });
  } else if (gr2 > 0 && gr3 === 0) {
    return filter(recipients, { gr1, gr2 });
  } else {
    return filter(recipients, { gr1, gr2, gr3 });
  }
};

export const findRegion = (selectedRecipient: any, regions: any) => {
  if (selectedRecipient) {
    const { gr1, gr2, gr3, gr4 } = selectedRecipient;
    if (gr1 > 0 && gr2 === 0) {
      return find(regions, { gr1 });
    } else if (gr2 > 0 && gr3 === 0) {
      return find(regions, { gr1, gr2: 0 });
    } else if (gr3 > 0 && !gr4) {
      return find(regions, { gr1, gr2, gr3: 0 });
    } else {
      return find(regions, { gr1, gr2, gr3 });
    }
  }
};

export const checkCountInName = (item: any) => {
  const counts = ["10шт", "10 шт", "100шт", "100 шт", "10pcs", "10 pcs", "100pcs", "100 pcs"];
  const includesCount: boolean = Boolean(
    filter(
      map(counts, (count) => Boolean(item.naim_r.includes(count) || item.naim_e.includes(count))),
      (item) => item,
    ).length,
  );
  const count: number = item.naim_r.includes("100") ? 100 : 10;
  return { includesCount, count };
};

export const getModifiedNumber = (value: number, count: number) => {
  return value?.toFixed(count).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  // return Number(value?.toFixed(count)).toLocaleString();
};

export const getModifiedCalendarStrings = (strings: any) => ({
  ...strings,
  shortMonths: map(new Array(12), (_, i: number) => `${i + 1}`),
});

export const setCursorToStart = (e: any) => {
  setTimeout(() => {
    e.target.setSelectionRange(0, 0);
  }, 0);
};

export const validateDemicalNumber = (
  e: any,
  setFieldValue: any,
  field: string,
  integerLength: number,
  fractionalLength: number,
) => {
  const value = e.target.value;
  const array = value.split(".");
  if (array.length === 1) {
    value.length <= integerLength && setFieldValue(field, value);
  } else if (array.length === 2) {
    array[1]?.length <= fractionalLength && setFieldValue(field, value);
  }
};

export const getDisabledStyle = (condition: boolean) => (condition ? { color: "red" } : {});

export const startTimer = (func: () => void, interval: number) => {
  let current = 0;
  const end = interval || 300; // 5 min

  let timerId = setInterval(function () {
    // console.log(current);
    if (current === end) {
      func();
      clearInterval(timerId);
    }
    current++;
  }, 1000);
};

export const getDifferenceInDays = (deadlineDate: string | Date | null | undefined) => {
  return differenceInCalendarDays(new Date(deadlineDate || ""), new Date());
};

export const getMaxCountErrorMessage = (itemsLength: number, counterpartiesSelected?: boolean) =>
  itemsLength > 2000
    ? counterpartiesSelected
      ? loc.warnings.tooManySelectedCounterparties
      : loc.warnings.tooManySelectedItems
    : "";
