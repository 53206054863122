import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from "react-router-dom";
import { Navbar } from "../components/common/Navbar";
import { LeftMenu } from "../components/common/LeftMenu";
import { DashboardPage } from "../components/pages/DashboardPage";
import { AccessDeniedPage } from "../components/pages/AccessDeniedPage";
import { NotFoundPage } from "../components/pages/NotFoundPage";
import { useLayerEventListener } from "../utils/useEventListener";
import { onPressEnter } from "../utils/detailsListKeydown";
import { FaqPage } from "../components/pages/FaqPage";

const fallback = (
  <div className="page-wrapper">
    <Navbar />
    <div className="page-wrapper-content">
      <LeftMenu />
      <div className="page-wrapper__have-updates-wrapper" />
    </div>
  </div>
);

export const ProtectedRoute = ({ children, isAllowed }: any) => {
  const { pathname } = useLocation();
  if (!isAllowed) {
    return <Redirect exact to={{ pathname: "/access-denied", state: { from: pathname } }} />;
  }
  return children;
};

const DictionaryRouter = React.lazy(() => import(`./router.dictionary`));
const OperationsRouter = React.lazy(() => import(`./router.operations`));
const PriceFormationRouter = React.lazy(() => import(`./router.priceFormation`));
const ServiceRouter = React.lazy(() => import(`./router.service`));
const ReportsRouter = React.lazy(() => import(`./router.reports`));

export const ApplicationRouter = () => {
  useLayerEventListener("keydown", onPressEnter);
  return (
    <Router>
      <Suspense fallback={fallback}>
        <Switch>
          <Redirect exact path="/" to="/dashboard" />
          <Route exact path="/dashboard" component={DashboardPage} />
          <Route path="/access-denied" component={AccessDeniedPage} />
          <Route path="/reference-books" component={DictionaryRouter} />
          <Route path="/operations" component={OperationsRouter} />
          <Route path="/price-formation" component={PriceFormationRouter} />
          <Route path="/service" component={ServiceRouter} />
          <Route path="/reports" component={ReportsRouter} />
          <Route path="/faq" component={FaqPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </Suspense>
    </Router>
  );
};
