import { Modal } from "@fluentui/react";
import { Loading } from "../../wrappers/LoadingWrapper";
import { ErrorsBlock } from "../ErrorsBlock";
import "./styles.scss";

interface IConfirmModalProps {
  isLoading?: boolean;
  text: string;
  onDismiss: () => void;
  leftButton?: JSX.Element;
  rightButton?: JSX.Element;
  additionalButton?: JSX.Element;
  preLine?: boolean;
  warning?: string;
}

export const ConfirmModal = (props: IConfirmModalProps) => {
  return (
    <Modal
      isBlocking
      isOpen
      className={`confirm-modal shipment-basic-confirm ${props.warning ? "with-warning" : ""} ${
        props.additionalButton ? "additional-btn" : ""
      }`}
      onDismiss={props.onDismiss}
    >
      <form>
        <Loading isLoading={Boolean(props.isLoading)}>
          <>
            <div
              className="panel-content confirm-modal-text"
              style={{ whiteSpace: props.preLine ? "pre-line" : "initial" }}
            >
              {props.text}
            </div>
            <div className="bottom-block">
              {props.warning ? <ErrorsBlock warningStyle errors={[props.warning]} /> : null}
              <div className="modal-footer">
                {props.leftButton}
                {props.additionalButton && props.additionalButton}
                {props.rightButton && props.rightButton}
              </div>
            </div>
          </>
        </Loading>
      </form>
    </Modal>
  );
};
