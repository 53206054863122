import { useState } from "react";
import * as Yup from "yup";
import { DefaultButton, FontIcon, Modal, PrimaryButton, TextField } from "@fluentui/react";
import { useFormik } from "formik";
import loc from "../../../../localization";
import { putRequest } from "../../../../actions";
import { FAQ_ENDPOINT } from "../../../../constants/endpoints";
import { getNotification } from "../../../../utils";
import { Keydown, Notification } from "../../../../constants/enums";
import { Loading } from "../../../wrappers/LoadingWrapper";
import { RequiredField } from "../../../common/RequiredField";
import { ISelectedRecordInfo } from "..";

interface IEditRecordModalProps {
  selectedRecord: ISelectedRecordInfo;
  editOrder: () => void;
  onDismiss: () => void;
};

export const EditRecordModal = ({ onDismiss, editOrder, selectedRecord }: IEditRecordModalProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { titleEditRecord, question, answer } = loc.faqPage.panel;

  const submitHandler = () => {
    setIsLoading(true);
    putRequest(`${FAQ_ENDPOINT}/${selectedRecord.id}`, formik.values)
      .then((res) => {
        if (res.status === 200) getNotification("Success", "Record was updated successfully", Notification.SUCCESS);
        editOrder();
        onDismiss();
      })
      .catch((err) => {
        getNotification("Error", err.message, Notification.ERROR);
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      theme: selectedRecord.key,
      description: selectedRecord.name,
    },
    validateOnChange: false,
    validationSchema: Yup.object({
      theme: Yup.string().required(loc.warnings.requiredField),
      description: Yup.string().required(loc.warnings.requiredField),
    }),
    onSubmit: submitHandler,
  });

  return (
    <Modal isBlocking isOpen onDismiss={onDismiss} className="add-faq-modal">
      <form onSubmit={formik.handleSubmit}>
        <div className="header-block">
          <div className="ms-Panel-commands">
            <div className="modal-title">
              {titleEditRecord}
              <FontIcon onClick={onDismiss} iconName="ChromeClose" />
            </div>
          </div>
        </div>
        <div className="panel-content-wrapper">
          <Loading isLoading={isLoading}>
            <div className="panel-content">
              <div className="panel-content__names">
                <div className="panel-content__names-list">
                  <div className="name-container">
                    <div className="name-container-label">
                      <RequiredField text={question} />
                    </div>
                    <div className="name-container-value">
                      <TextField
                        name={"theme"}
                        onChange={formik.handleChange}
                        errorMessage={formik.errors.theme}
                        value={formik.values.theme}
                        maxLength={300}
                        multiline
                        autoAdjustHeight
                        onKeyDown={(e) => {
                          if (e.key === Keydown.Enter) e.preventDefault()
                        }}
                      />
                    </div>
                  </div>
                  <div className="name-container">
                    <div className="name-container-label">
                      <RequiredField text={answer} />
                    </div>
                    <div className="name-container-value">
                      <TextField
                        name={"description"}
                        onKeyDown={(e) => {
                          if (e.key === Keydown.Enter) e.preventDefault()
                        }}
                        onChange={formik.handleChange}
                        errorMessage={formik.errors.description}
                        value={formik.values.description}
                        maxLength={2000}
                        multiline
                        autoAdjustHeight
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Loading>
        </div>
        <div className="bottom-block">
          <div className="modal-footer">
            <PrimaryButton text={loc.buttons.save} type="submit" />
            <DefaultButton text={loc.buttons.cancel} onClick={onDismiss} />
          </div>
        </div>
      </form>
    </Modal>
  );
};